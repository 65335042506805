import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment as ENV } from 'src/environments/environment';
import { Storage } from '@ionic/storage-angular';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Observable, throwError, from } from 'rxjs';
import { Router } from '@angular/router';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private storage: Storage, private router: Router){ }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('/auth/local') || request.url.includes('home-servicios')) {
      return next.handle(request);
    }

    return from(this.storage.get('USER'))
      .pipe(
        switchMap(user => {
          if (user) {
              request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + user.token) });
          }
          else{
            this.router.navigate(['login']);
          }

          console.log('URL A ENVIAR', request.url);
          if (!request.headers.has('Content-Type') && !request.url.endsWith('/upload')) {
              request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
          }

          //if (this.debug) {
          //    request = request.clone({ url: this.url + request.url + '?XDEBUG_SESSION_START=1'});
          //}

          return next.handle(request).pipe(
              map((event: HttpEvent<any>) => {
                  if (event instanceof HttpResponse) {
                      // do nothing for now
                  }
                  return event;
              }),
              catchError((error: HttpErrorResponse) => {
                  const status =  error.status;
                  const reason = error && error.error.reason ? error.error.reason : '';

                  //this.presentAlert(status, reason);
                  console.log('ERROR TRYing to request to server');
                  return throwError(error);
              })
          );
        })
      );


}

  /*intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let promise = this.storage.get('USER');



    if (req.url.includes('/auth/local')) {
      return next.handle(req);
    }

    this.storage.get('USER').then(r => {
      const headers = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${r.token}`)
      });
      return next.handle(headers);
    });

    return next.handle(req);

  }*/
}
