import { HttpClient } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import * as moment from "moment";
import { BehaviorSubject } from "rxjs";
import { environment as ENV } from '../../../environments/environment';
import { AppService } from "../../services/app.service";
import { MovimientosService } from "./movimientos/movimientos.service";

@Injectable({
  providedIn: 'root'
})
export class CajaService implements OnInit{
    cajaActual = new BehaviorSubject<any>(null);
    // ***** Agregar en los guards de Ordenes la caja actual que exista.
    constructor(private http: HttpClient, private appService: AppService,
                private movimientosService : MovimientosService){

        this.movimientosService.nuevoMovimiento.subscribe( (mov:any) => { //_ Agregar nuevo movimiento y actualizar caja.
          const cajaX:any = this.cajaActual.getValue();
          cajaX.movimientos.push(mov);
          cajaX.Total = mov.saldo_nuevo;
          this.cajaActual.next(cajaX);
        });
    }

    ngOnInit(){

    }

    async getCajaAbierta(){
      return await this.http.get(ENV.apiServer + ENV.apiPrefix + '/caja/abierta').toPromise().then( async (r:any) => {
        if (r.length > 0){
          this.cajaActual.next(r[0]);
          console.log('CAJA OBTENIDA', r[0]);
          return r[0];
        }
        else{
          await this.AperturarCaja('Caja inicial');
          return null;
        }
      }).catch(e => {
        console.log('ERROR GETTING CAJA ABIERTA', e);
        return null;
      });
    }

    async AperturarCaja(nombre = ''){
      const data = {
        Nombre: nombre,
        usuario_apertura: this.appService.user,
        fecha_apertura: moment().unix()*1000,
        Total: 0,
        negocio: this.appService.user.negocio,
        Estado: 'Abierta'
      };

      return await this.http.post(ENV.apiServer + ENV.apiPrefix + '/cajas?populate=*', { data }).toPromise().then( async (r:any) => {
        if (r.data){
          this.cajaActual.next(r.data);
        }
      }).catch(e => {
        return null;
      });
    }

    async cerrarCaja(){
      const data = {
        usuario_cierre: this.appService.user,
        fecha_cierre: moment().unix()*1000,
        Total: this.cajaActual.value.Total,
        Estado: 'Cerrada'
      };

      return await this.http.put(ENV.apiServer + ENV.apiPrefix + '/cajas/'+this.cajaActual.value.id, { data }).toPromise().then( async (r:any) => {
        return r.data;
      }).catch(e => {
        return null;
      });
    }

  }
