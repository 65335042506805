import { HttpClient } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { environment as ENV } from '../../../../environments/environment';
import { sanitizeStrapyObject } from "src/app/util/sanitize-strapi-response";
import * as qs from 'qs';

@Injectable({
  providedIn: 'root'
})
export class MovimientosService {
  nuevoMovimiento = new Subject();
  constructor(private http: HttpClient){

  }

  async crearMovimiento(data){
    return await this.http.post(ENV.apiServer + ENV.apiPrefix + '/movimientos/nuevo', { data }).toPromise().then( async (r:any) => {
      console.log('Nuevo movimiento', r)
      this.nuevoMovimiento.next(r);
      return r;
    }).catch(e => {
      console.log('ERROR GETTING CLEINTES', e);
      return null;
    });
  }

  async getTiposMovimiento(){
    return await this.http.get(ENV.apiServer + ENV.apiPrefix + '/movimiento-tipos?populate=*').toPromise().then( async (r:any) => {
      console.log('Tipos de movimiento', r.data)
      return r.data;
    }).catch(e => {
      console.log('ERROR GETTING TIPOS', e);
      return null;
    });
  }

  async getMovimientos(cajaId, negocioId){
    const query = qs.stringify({
      filters: {
        $and: [
          {
            negocio: negocioId,
            caja: cajaId,
          },
        ],
      },
      sort: ['createdAt:desc'],
      populate: '*'
    }, {
      encodeValuesOnly: true, // prettify URL
    });

    return await this.http.get(ENV.apiServer + ENV.apiPrefix + '/movimientos?' + query).toPromise().then( async (r:any) => {
      console.log('Movimientos', r)
      return r.data;
    }).catch(e => {
      console.log('ERROR GETTING Movimientos', e);
      return null;
    });
  }
}

export enum tiposMovimiento {
  IngresoOrden = 7, //_ Id del TIPO DE MOVIMIENTO = Ingreso de orden
  EgresoOrden = 8, //_ Id del TIPO DE MOVIMIENTO = Egreso de orden
};
