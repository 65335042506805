// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appToken: '',
  apiServer: 'https://fotovaldez-back.nuveapps.com/',
  uploadUrl: 'https://fotovaldez-back.nuveapps.com/',
  // apiServer: 'http://localhost:1337/',
  // uploadUrl: 'http://localhost:1337',
  apiPrefix: 'api',
  APP_TOKEN: 'b6c9b60f82c22d470d9ee649632ae568752b9e1ca5480288b38fa879998b77943b97a8a57057681f82ae442faee7dcb7734d4f9c72d42775f036f85e17ef067ced536aac0ce9b0241c685763310293244a76d78b640f05ee21cdb0efd7ad98ff263a3a4b50dcb9ba492e2623d10e08be211798d892d91d39c5c82c642ff369d6'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
