import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { AppService } from './services/app.service';
import { AuthService } from './services/auth.service';
import { CajaService } from './ventas/caja/caja.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private storage: Storage, private cajaService: CajaService, private appService: AppService, private authService: AuthService) {}

  user = null;
  async ngOnInit(){
    await this.storage.create();

    this.authService.isLogged.subscribe( r => {
      if (r){
        if (this.appService.user.role.type != 'admin')
          this.cajaService.getCajaAbierta();
      }
    });


  }
}
