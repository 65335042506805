

interface menuNodo {
  nombre: string;
  ruta?: string;
  link?: string;
  icono?: string;
  open?: boolean;
  hijos?: menuNodo[];
}

export const ventasMenu: menuNodo[] = [
  {
    nombre: 'Caja',
    ruta: '/ventas/caja',
    icono: 'assets/images/sidebar/caja.svg',
    hijos: [
      {
        nombre: 'Movimientos',
        ruta: '/ventas/caja/movimientos',
        icono: 'assets/images/ordenes/add.svg',
      },
    ]
  }, {
    nombre: 'Ordenes',
    ruta: '/ventas/ordenes',
    icono: 'assets/images/sidebar/ordenes.svg',
    hijos: [
      {
        nombre: 'Nueva orden',
        ruta: '/ventas/ordenes/nueva',
        icono: 'assets/images/ordenes/add.svg',
      },{
        nombre: 'Todas las ordenes',
        ruta: '/ventas/ordenes/list',
        icono: 'assets/images/ordenes/orders.svg',
      },
    ]
  },
  {
    nombre: 'Clientes',
    ruta: '/ventas/clientes',
    icono: 'assets/images/sidebar/clientes.svg',
    hijos: [
      {
        nombre: 'Nuevo cliente',
        ruta: '/ventas/clientes/nuevo-cliente',
        icono: 'assets/images/ordenes/add.svg',
      }
    ]
  },
];

export const adminMenu: menuNodo[] = [
  {
    nombre: 'Dashboard',
    ruta: '/admin/dashboard',
    icono: 'assets/images/sidebar/caja.svg',
  }, {
    nombre: 'Cajas',
    ruta: '/admin/cajas',
    icono: 'assets/images/sidebar/ordenes.svg',
  }
];
