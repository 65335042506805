import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationEnd, NavigationEnd, Router, RouteReuseStrategy } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { CajaService } from 'src/app/ventas/caja/caja.service';
import { ventasMenu, adminMenu } from './menu-tree';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  caja = null;
  user = null;
  menuList = ventasMenu;
  rutaActual = '';
  obs = [];
  hideNavMenu = true;
  constructor(private authService: AuthService, private cajaService: CajaService,
              private appService: AppService, private router: Router, private route: ActivatedRoute,
              private menu: MenuController) { }


  ngOnInit() {
    this.caja = this.cajaService.cajaActual.value;
    this.obs['cajaActual'] = this.cajaService.cajaActual.subscribe( c => {
      this.caja = c
    });

    this.obs['isLogged'] = this.authService.isLogged.subscribe( r => {
      if (r){
        this.user = this.appService.user;
        this.menu.enable(true, 'sidebar');
        if (this.user.role.type == 'admin')
          this.menuList = adminMenu;
        else
          this.menuList = ventasMenu;
      }else
        this.user = null;
    });

   this.obs['router'] = this.router.events.subscribe( evento => { //_ Expand menu tree
      if (evento instanceof  ActivationEnd){
        this.rutaActual = this.route.snapshot['_routerState'].url;
        console.log('RUTA ACTUAL', this.rutaActual);
        this.menuList.forEach( menu => {
          if (this.rutaActual.startsWith(menu.ruta) && menu.hijos)
            menu.open = true;
        });
      }
    })

    this.menu.enable(false, 'navMenu');
    setTimeout( () => this.hideNavMenu = false, 500);
  }

  logout(){
    this.authService.logout();
  }

  expandMenu(menu, ev){
    menu.open = !menu.open;
    ev.stopPropagation();
  }

  navegar(ruta){
    this.router.navigate([ruta]);
  }

  ngOnDestroy(): void {
    Object.keys(this.obs).forEach(k => this.obs[k].unsubscribe() );
  }

  goTo(where){
    if (where == 'services')
      document.getElementById('servicios-header').scrollIntoView();

    if (where == 'contactanos')
      document.getElementById('contactanos-header').scrollIntoView();

    if (where == 'reload')
      location.reload();
    if (where == 'login')
      location.href = '/login'

    this.menu.close('navMenu');
  }
}
