import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AppService } from 'src/app/services/app.service';
import { MovimientosService } from '../../movimientos/movimientos.service';

@Component({
  selector: 'app-ingreso-egreso',
  templateUrl: './ingreso-egreso.component.html',
  styleUrls: ['./ingreso-egreso.component.scss'],
})
export class IngresoEgresoComponent implements OnInit {
  @Input() tipo = 'ingreso';

  tipos;
  importe = 0;
  tipoSeleccionado = null;
  detalle = null;
  constructor(private movimientosService: MovimientosService, private popover: PopoverController,
              private appService: AppService) { }
  ngOnInit() {
    this.cargarTipos();
  }

  cargarTipos(){
    this.movimientosService.getTiposMovimiento().then( r => {
      if (this.tipo == 'ingreso')
        this.tipos = r.filter( t => t.Tipo == 'Ingreso');
      if (this.tipo == 'egreso')
        this.tipos = r.filter( t => t.Tipo == 'Egreso');
      this.tipoSeleccionado = 0;
    });
  }

  cerrar(){
    this.popover.dismiss();
  }

  aceptar(){
    if (this.detalle)
      this.popover.dismiss({ tipo: this.tipos[this.tipoSeleccionado], importe: Math.abs(this.importe), detalle: this.detalle });
    else{
      this.appService.showToast('', 'Debe de ingresar una razon o motivo.', 3000, 'danger');
    }
  }

}
