import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot, UrlSegment, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '../services/app.service';

@Injectable({
  providedIn: 'root'
})
export class VendedorGuard implements CanActivate, CanLoad {

  constructor(private appService: AppService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this.appService.user){
        if (this.appService.user.role.type == 'vendedor'){
          this.appService.showToast(null, 'Bienvenid@ ' + this.appService.user.Nombre, 2000, 'success', 'top');
          return true;
        }

      }

      this.router.navigate(['/login']);
      this.appService.showToast('No estas autenticado', 'Debes de iniciar sesion para acceder a esta pagina', 3000, 'danger');
      return false;
  }

  async canLoad( route: Route, segments: UrlSegment[] ) {
    if (!this.appService.user)
      await this.appService.ngOnInit();

    if (this.appService.user)
      return true;
    else {
      this.router.navigate(['/login']);
      this.appService.showToast('No estas autenticado', 'Debes de iniciar sesion para acceder a esta pagina', 3000, 'danger');
      return false;
    }

  }

}
