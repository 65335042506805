import { HttpClient, HttpHeaderResponse, HttpHeaders } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { environment as ENV } from '../../environments/environment';
import { Storage } from '@ionic/storage';
import { Platform, ToastController } from "@ionic/angular";
import { Router } from "@angular/router";
import { AppService } from "./app.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLogged = new BehaviorSubject(false);
  constructor(private storage: Storage, private router: Router, private appService: AppService,
              private platform: Platform, private http: HttpClient){
    this.platform.ready().then(() => {
      this.checkLogin();
    });
  }

  login(user){
    this.storage.set('USER', user).then( async (r) => {
      this.isLogged.next(true);
    });
  }

  async doLogin(identifier:string, password:string){
    let data = {identifier, password}
    return await this.http.post(ENV.apiServer + ENV.apiPrefix + '/auth/local', data).toPromise().then( async (r:any) => {
      if (r.jwt){
        r.user.token = r.jwt;

        this.getUserData(r.jwt);
        const userData = await this.getUserData(r.jwt);

        this.appService.user = userData;
        this.login(userData);
        return userData;
      }
      return null;
    });
}

  checkLogin() {
    this.storage.get('USER').then( async (r) => {
      if (r) {
        await this.storage.set('token', r.jwt);
        this.isLogged.next(true);
      }
    });
  }

  logout() {
    this.storage.remove('USER').then( async () => {
      this.appService.user = null;
      await this.storage.remove('token');
      this.isLogged.next(false);
      this.router.navigate(['login']);
    });
  }

  getUserData(token){
    const headerDict = { 'Authorization': 'Bearer ' + token }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return new Promise ((resolve, reject) => {
      this.http.get(ENV.apiServer + ENV.apiPrefix + '/users/me?populate=*', requestOptions).subscribe( (r: any) => {
        // console.log('ME RESPONSE', r);
        r.token = token;
        resolve(r);
      }, (e) => {
        reject(e);
      })
    })

  }

}
