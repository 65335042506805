import { HttpClient } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { environment as ENV } from '../../environments/environment';
import { Storage } from '@ionic/storage';
import { AlertController, ToastController } from "@ionic/angular";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class AppService implements OnInit {

    user = null;
    constructor(private http: HttpClient, private storage: Storage,
                private toast: ToastController,
                private alert: AlertController){

    }

    async ngOnInit(){
      this.user = await this.storage.get('USER');
    }

    myToast:any = null;
    async showToast(title, text, time, colorType, pos:any='bottom') {
        if (this.myToast) this.myToast.dismiss();

        this.myToast = await this.toast.create({
            message: text,
            duration: time,
            color: colorType,
            position: pos,
            header: title
        });

        this.myToast.present();
    }

    async confirmDialog(title, message, cssClass='', buttons={no: 'No', yes: 'Si'}){
      return new Promise( (resolve, reject) => {
          this.alert.create({
              header: title,
              //subHeader: 'Beware lets confirm',
              message: message,
              cssClass: cssClass,
                  buttons: [
                  {
                      text: buttons.no,
                      handler: () => { resolve(false); }
                  },
                  {
                      text: buttons.yes,
                      handler: () => { resolve(true); }
                  }
              ]
          }).then(async res => {
              await res.present();
          });
      });
  }
}
